import { FC, useCallback, useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import DOMPurify from 'isomorphic-dompurify'
import 'react-multi-carousel/lib/styles.css'
import parse from 'html-react-parser'
import debounce from '@propertylens/app-commons/dist/utils/debounce'

import CustomDot from '../CustomDot'
import { responsiveItemsBreakpoints as responsive } from '../../config/responsiveBreakpoints'

interface InfoItem {
  infoItem: {
    value: {
      id: string
      data: {
        icon?: string
        title: string
        subtitle?: string
      }
    }
  }
}

interface InfoItemsSliderProps {
  infoItems: InfoItem[]
}

const InfoItemsSlider: FC<InfoItemsSliderProps> = ({ infoItems }) => {
  let MOBILE_BREAKPOINT = false
  const [isClient, setIsClient] = useState(false)
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? MOBILE_BREAKPOINT : false
  )

  if (isClient) {
    MOBILE_BREAKPOINT = window?.innerWidth < 992
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  // We are listening to resize to render the slider only on mobile
  const handleResize = useCallback(() => {
    isClient && typeof window !== 'undefined' && setIsMobile(MOBILE_BREAKPOINT)
  }, [isClient, MOBILE_BREAKPOINT])

  useEffect(() => {
    if (window) {
      const debounceResize = debounce(handleResize, 200)
      setIsMobile(MOBILE_BREAKPOINT)
      // Attach the resize event listener
      window.addEventListener('resize', debounceResize)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', debounceResize)
      }
    }
  }, [handleResize, isMobile, MOBILE_BREAKPOINT])

  if (!infoItems?.length || !isMobile) return null

  if (isMobile)
    return (
      <div className="pb-8">
        <Carousel
          draggable
          swipeable
          responsive={responsive}
          showDots
          autoPlay
          autoPlaySpeed={3000}
          infinite
          transitionDuration={500}
          renderDotsOutside
          containerClass="info-carousel-container"
          customDot={<CustomDot />}
          dotListClass="info-carousel-dot-list"
          renderButtonGroupOutside={true}
          pauseOnHover
        >
          {infoItems.map((item) => {
            if (!item.infoItem?.value?.data?.title) return null
            return (
              <div
                key={item.infoItem.value.id}
                className="mx-2 bg-[#f9fafb] rounded-[24px] border-1 border-[#e1e3e8] border-solid flex flex-col min-h-[158px] p-4 justify-between"
              >
                <div>
                  {item.infoItem.value.data?.icon &&
                    parse(DOMPurify.sanitize(item.infoItem.value.data?.icon))}
                </div>
                <div>
                  <div className="font-inter text-lg font-semibold mb-1 text-wrap leading-[1.2em]">
                    {item.infoItem.value.data?.title}
                  </div>
                  {item.infoItem.value.data?.subtitle && (
                    <span className="text-[15px] font-inter font-[500] text-[rgb(75,85,99)] leading-[1em]">
                      {item.infoItem.value.data?.subtitle}
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    )
}

export default InfoItemsSlider
