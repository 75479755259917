import { FC } from 'react'
import { DotProps } from 'react-multi-carousel'

const CustomDot: FC<DotProps> = ({ onClick, ...rest }) => {
  const { active } = rest
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <span className={active ? 'active' : 'inactive'} onClick={() => onClick()}>
      •
    </span>
  )
}

export default CustomDot
